<template>
  <div>
    <v-row>
      <v-col :cols="cols">
        <label>
          {{ title }}
        </label>
        <v-text-field
          filled
          outlined
          hide-details="auto"
          :label="name"
          id="id"
          :counter="length"
          v-if="typeName == 'text box'"
        ></v-text-field>
        <v-textarea
          filled
          outlined
          hide-details="auto"
          :label="name"
          id="id"
          :counter="length"
          v-if="typeName == 'text area'"
        ></v-textarea>
        <v-select
          filled
          outlined
          hide-details="auto"
          :label="name"
          :items="values"
          :multiple="typeName == 'Multiple Select'"
          id="id"
          v-if="typeName == 'Single select' || typeName == `Multiple select`"
        ></v-select>
        <div v-if="typeName == 'Check box'">
          <v-checkbox
            filled
            outlined
            hide-details="auto"
            id="id"
            v-for="(value, index) in values"
            :key="index"
            :label="value"
          ></v-checkbox>
        </div>
        <div v-if="typeName == 'Radio button'">
          <v-radio-group
            :label="name"
            row
            filled
            outlined
            hide-details="auto"
            id="id"
          >
            <v-radio
              v-for="(value, index) in values"
              :key="index"
              :label="value"
            ></v-radio>
          </v-radio-group>
        </div>
        <v-menu
          v-if="typeName == 'Date and time'"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :label="name"
              outlined
              append-icon="mdi-calendar"
              readonly
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker locale="ar"></v-date-picker>
        </v-menu>
        <div v-if="typeName == 'ZainCash'" class="zain d-flex align-center justify-content-between" style="border: #ddd 1px solid">
          <h3 class="mr-1">ادفع بأستخدام </h3>
          <v-img contain height="75" src="@/assets/zaincash.png"></v-img>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  computed: {
    typeName() {
      let field = this.$global.state.fieldsTypes.filter(
        (item) => item.id == this.type
      );
      return field.length > 0 ? field[0].name : "text box";
    },
    values() {
      if (
        this.typeName == "Single select" ||
        this.typeName == "Multiple select" ||
        this.typeName == "Check box" ||
        this.typeName == "Radio button"
      ) {
        return this.value.split(",").length > 0 ? this.value.split(",") : [];
      } else {
        return this.value;
      }
    },
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    cols: {
      tyep: Number,
      default: 12,
    },
    name: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    length: {
      type: Number,
      default: 100,
    },
  },
  methods: {},
  watch: {
    cols(val) {
      console.log(val);
    },
  },
};
</script>
