<template>
  <div>
    <v-dialog v-model="$store.state.fieldDialog" width="1000">
      <v-card flat class="card">
        <v-toolbar outlined flat color="grey lighten-4">
          <v-toolbar-title
            >{{
              $store.state.itemDetails.dialogType == "add"
                ? $t("idhafh")
                : $t("edit")
            }}
            {{ $t("hql") }}</v-toolbar-title
          >
          <v-spacer />
          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form @submit.prevent="submit()" ref="form">
          <v-container>
            <v-row>
              <v-col cols="12" md="8">
                <v-card outlined>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6">
                        <h3 class="mb-2">
                          {{ $t("alism") }} <span class="required">*</span>
                        </h3>
                        <v-text-field
                          v-model="item.name"
                          :placeholder="$t('alism')"
                          :rules="[$global.state.required()]"
                          required
                          filled
                          outlined
                          hide-details="auto"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <h3 class="mb-2">
                          {{ $t("anwan-alhql") }}<span class="required">*</span>
                        </h3>
                        <v-text-field
                          v-model="item.title"
                          :placeholder="$t('anwan-alhql')"
                          :rules="[$global.state.required()]"
                          required
                          filled
                          outlined
                          hide-details="auto"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <h3 class="mb-2">
                          النوع <span class="required">*</span>
                        </h3>
                        <v-select
                          :items="$global.state.fieldsTypes"
                          v-model="item.fieldTypeId"
                          :placeholder="$t('alnwa')"
                          item-text="name"
                          item-value="id"
                          :rules="[$global.state.required()]"
                          required
                          filled
                          outlined
                          hide-details="auto"
                          @change="$forceUpdate()"
                        />
                      </v-col>
                      <v-col cols="6">
                        <h3 class="mb-2">
                          {{ $t("rsalh-alkhta") }}
                          <span class="required"></span>
                        </h3>
                        <v-text-field
                          v-model="item.errorMessage"
                          :placeholder="$t('rsalh-alkhta')"
                          required
                          filled
                          outlined
                          hide-details="auto"
                        />
                      </v-col>
                      <!-- <v-col cols="6">
                                            <h3 class="mb-2">
                                                المساحة <span class="required">*</span>
                                            </h3>
                                            <v-text-field v-model="item.cols" placeholder="المساحة" type="number" :rules="[$global.state.required()]" required filled outlined max="12" min="2" hide-details="auto" />
                                        </v-col> -->
                      <v-col cols="12" md="6">
                        <h3 class="mb-2">
                          {{ $t("alqymh") }} <span class="required"></span>
                        </h3>
                        <v-text-field
                          v-model="item.value"
                          :placeholder="$t('alqymh')"
                          required
                          filled
                          outlined
                          hide-details="auto"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <h3 class="mb-2">
                          {{ $t("mlahzh") }}
                        </h3>
                        <v-text-field
                          v-model="item.note"
                          :placeholder="$t('mlahzh')"
                          required
                          filled
                          outlined
                          hide-details="auto"
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <h3 class="mb-2">
                          {{ $t("twl-alns") }}
                        </h3>
                        <v-text-field
                          v-model="item.length"
                          :placeholder="$t('twl-alns')"
                          required
                          type="number"
                          filled
                          outlined
                          hide-details="auto"
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <h3 class="mb-2">
                          {{ $t("aala-qymh") }}
                        </h3>
                        <v-text-field
                          v-model="item.max"
                          :placeholder="$t('aala-qymh')"
                          type="number"
                          required
                          filled
                          outlined
                          hide-details="auto"
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <h3 class="mb-2">
                          {{ $t("aql-qymh") }}
                        </h3>
                        <v-text-field
                          v-model="item.min"
                          :placeholder="$t('aql-qymh')"
                          type="number"
                          required
                          filled
                          outlined
                          hide-details="auto"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card flat>
                  <v-card-title> {{ $t("shkl-alhql") }}</v-card-title>
                  <v-card-text>
                    <preview-field
                      :type="item.fieldTypeId"
                      :name="item.name"
                      :title="item.title"
                      :value="item.value"
                      :cols="item.cols"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-col cols="12" md="12">
              <v-row class="mt-2" justify="end">
                <v-btn
                  @click="close()"
                  x-large
                  color="error"
                  class="mx-2 btn"
                  outlined
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  type="submit"
                  :loading="$global.state.loading"
                  x-large
                  color="primary"
                  class="btn"
                  >{{ $t("save") }}</v-btn
                >
              </v-row>
            </v-col>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import previewField from "./preview-field.vue";
export default {
  components: {
    previewField,
  },
  data() {
    return {
      item: {},
    };
  },

  mounted() {
    this.reset();

    this.$eventBus.$on("fill-fields", () => {
      if (this.$store.state.addFieldDialog != true) {
        console.log(this.$store.state.itemDetails);
        this.item = this.$store.state.itemDetails;
        this.item.fieldTypeId = this.item.fieldType.id;
      }
    });
  },

  methods: {
    reset() {
      this.item = {
        name: "",
        fieldTypeId: "1e7197fa-e7a6-491c-9afa-97b6380e5c8d",
        length: 0,
        // cols: 12,
        min: 0,
        max: 0,
        value: "",
        title: "",
        errorMessage: "",
        note: "",
      };
    },

    submit() {
      if (
        this.$store.state.itemDetails.dialogType == "add" ||
        this.$store.state.addFieldDialog == true
      ) {
        this.addItem();
      } else {
        this.editItem();
      }
    },

    addItem() {
      this.$global.state.loading = true;
      this.$http
        .post(`Field`, this.item)
        .then((res) => {
          this.$global.state.specialties = [];
          this.$service.success(this.$t("tm-alidhafh-bnjah"));
          this.$eventBus.$emit(`refresh`);
          this.close();
        })
        .catch((err) => {
          this.$service.faild(err.response.data.message);
        })
        .finally(() => (this.$global.state.loading = false));
    },

    async editItem() {
      this.$global.state.loading = true;
      this.$http
        .put(`Field/${this.item.id}`, this.item)
        .then((res) => {
          this.close();
          this.$service.success(this.$t("tm-altadyl-bnjah"));
          this.$eventBus.$emit(`refresh`);
        })
        .catch((err) => {
          this.$service.faild(err.response.data.message);
        })
        .finally(() => (this.$global.state.loading = false));
    },

    close() {
      if (this.$store.state.addFieldDialog != true) {
        this.reset();

        this.$store.commit("setItemDetails", {});
      }
      this.$store.commit("setFieldDialog");
      this.resetValidation();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
